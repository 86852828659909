import React, { useState } from 'react';
import SuccessMessage from '../components/successMessage';

const Support = () => {
  const [selectOption, setSelectOption] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);

  const sendEmail = (submit_evnt) => {
    submit_evnt.preventDefault();
    const form_data = { selectOption, name, email, message };
    fetch('https://cdn.mrselmani.com/mailer/vegasdaveofficial.php', {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(form_data)
    })
    .then((response) => {
      if (response.status === 200) {
        setFormSuccess(true);
      } else {
        console.log('Something went wrong on the form submission, more details: ');
        console.log(response);
      }
    });
  };

  return (
    <div className="support_page">
      <div className="sl_container">
        <h1>FOR CUSTOMER SUPPORT, QUESTIONS OR PAYMENT ISSUES</h1>
        <p>FILL OUT THE FORM BELOW AND WE’LL GET IN TOUCH AS SOON AS POSSIBLE</p>
        {formSuccess ? (
          <SuccessMessage message="Thanks for your submission, we will get in touch soon! 😀" />
        ) : (
          <form onSubmit={sendEmail}>
            <select 
              onChange={(evnt) => setSelectOption(evnt.target.value)} 
              required={true} >
              <option value="General Questions">General Questions</option>
              <option value="Packages and Pricing">Packages and Pricing</option>
              <option value="Technical Support">Technical Support</option>
              <option value="Affiliate Support">Affiliate Support</option>
              <option value="Billing and Cancellations">Billing and Cancellations</option>
            </select>
            <div className="two_columns">
              <input 
                type="text"
                placeholder="Full Name"
                onChange={(evnt) => setName(evnt.target.value)}
                required={true}
                value={name}
              />
              <input 
                type="email"
                placeholder="Email"
                onChange={(evnt) => setEmail(evnt.target.value)}
                required={true}
                value={email}
              />
            </div>
            <textarea 
              placeholder="Your Message"
              onChange={(evnt) => setMessage(evnt.target.value)}
              required={true}
              value={message}
            ></textarea> 
            <button type="submit">SUBMIT</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Support;
