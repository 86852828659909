import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [videoPlayed, setVideoPlayed] = useState(false);

  return (
    <div className='home_page'>
      <div className="hero_section"> 
        <div className="sl_container"> 
          <h1>
            <span className='yellow_txt'>TO BE LIKE THE GOAT,</span>
            <strong className='larget_text'>BET WITH THE <span className="glow_txt">GOAT</span></strong> 
          </h1>
          <div className="video_part">
            <div className="video_container">
              { videoPlayed ? (
                <iframe src="http://www.youtube.com/embed/W_ob5gTNve8?autoplay=true" allow="autoplay" frameborder="0" allowfullscreen></iframe>
              ) : (
                <div className="thumbnail_element" onClick={()=> { setVideoPlayed(true) }}>
                  <img src="/images/sddefault.jpg" className='thumb_img' />
                  <i className="fa-solid fa-play"></i>
                </div> 
              ) }
            </div>
            <div className="video_title">WATCH THIS VIDEO BEFORE YOU BET WITH THE GOAT</div>
          </div> 
        </div>
      </div>
      <div className="logos_section">
        <div className="sl_container"> 
          <div className="logo_container">
            <h2>FEATURED IN</h2>
          </div>
          <div className="logo_container"> 
            <img src="/images/nbc-light.png" />
          </div>
          <div className="logo_container"> 
            <img src="/images/showtime.png" />
          </div>
          <div className="logo_container"> 
            <img src="/images/espn.png" />
          </div>
          <div className="logo_container"> 
            <img src="/images/fox-sports.png" />
          </div>
          <div className="logo_container"> 
            <img src="/images/cbssports.png" />
          </div>
          <div className="logo_container"> 
            <img src="/images/yahoo-sports.png" />
          </div>
        </div>
      </div>
      <div className="about">
        <div className="sl_container"> 
          <div className="left_container">
            <img src="/images/slider-1.png" />
          </div>
          <div className="right_container">
            <h2>
              <span>A FEW WORDS ABOUT</span> “VEGAS DAVE”
            </h2>
            <div className="text_container">
              <p>VEGAS DAVE IS THE ” GOAT ” OF SPORTS BETTING. HE IS THE #1 SPORTS CONSULTANT IN THE WORLD.</p>
              <p>HE HAS BEEN FEATURED ON ESPN, FOX SPORTS, USA TODAY. YAHOO SPORTS, FORBES, AND MANY OTHER GLOBAL PLATFORMS WORLD WIDE.</p>
              <p>WHAT DAVE DOES IS HELP THOUSANDS OF PEOPLE MAKE MONEY THRU HIS EXPERTISE IN SPORTS.</p> 
            </div>
          </div>
        </div>
      </div>
      <div className="cards_section">
        <div className="sl_container">
          <div className="single_card">
            <img src="/images/club-1-color.png" />
            <h3>$2.5 MILLION ON THE KANSAS CITY ROYALS</h3>
            <p>Vegas Dave's 2.5 million dollar record breaking payout for a MLB future at 30-1 odds.</p>
            <Link to="/">LEARN MORE</Link>
          </div>
          <div className="single_card">
            <img src="/images/club-2-color.png" />
            <h3>$2.3 MILLION ON THE DENVER BRONCOS</h3>
            <p>Vegas Dave’s 2.3 million dollar record breaking payout for a NFL Super Bowl Future at 16-1 odds.</p>
            <Link to="/">LEARN MORE</Link>
          </div>
          <div className="single_card">
            <img src="/images/club-3-color.png" />
            <h3>$2 MILLION ON THE ATLANTA FALCONS</h3>
            <p>Vegas Dave's 2 Million Dollar Record Breaking Payout For A NFL Conference Future At 10-1</p>
            <Link to="/">LEARN MORE</Link>
          </div>
        </div>
      </div>
      <div className="packages_section" id="package-details">
        <div className="sl_container">
          <h2>PACKAGES</h2>
          <p>
            Start your winning streak with Vegas Dave 
            <br></br>
            This is your chance to work with the best in the business and get in on the action!
          </p>
          <div className="packages_container"> 
            <div className="single_package">
              <span className="new">NEW</span>
              <div className="pack_category">SEASON PACKAGES</div>
              <h3><span>BASEBALL</span> 2024</h3>
              <div className="pack_price">
                <small>from</small> <span>$</span> <b>1495</b> <small>USD</small>
              </div>
              <Link to="/">MORE DETAILS</Link>
            </div> 
            <div className="single_package"> 
              <div className="pack_category">DAILY PACKAGE</div>
              <h3><span>BASEBALL</span> 2024</h3>
              <div className="pack_price">
                <span>$</span> <b>199</b> <small>USD</small>
              </div>
              <Link to="/">PURCHASE</Link>
            </div> 
            <div className="single_package"> 
              <div className="pack_category">MAX BET PACKAGE</div>
              <h3><span>SUPER</span> GOAT</h3>
              <div className="pack_price">
                <span>$</span> <b>995</b> <small>USD</small>
              </div>
              <Link to="/">PURCHASE</Link>
            </div> 
          </div>
        </div>
      </div>
      <div className="social">
        <Link className="single_social">
          <img src="/images/instagram.png" />
          <span>@instagram.user</span>
        </Link>
        <Link className="single_social">
          <img src="/images/youtube.png" />
          <span>@YouTube.channel</span>
        </Link>
      </div>
    </div>
  )
}

export default Home
