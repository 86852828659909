import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer_container'>
      <div className="sl_container">
        <p className="hightlighted">IF YOU DON’T HAVE A CRYPTO ACCOUNT – CLICK HERE TO LEARN HOW TO GET ONE.</p>
        <p className='text_content'>
          VEGAS-DAVE.COM IS NOT A GAMBLING SITE, AND DOES NOT ACCEPT OR PLACE WAGERS OF ANY TYPE. THIS WEBSITE DOES NOT ENDORSE OR ENCOURAGE ILLEGAL GAMBLING. ALL INFORMATION PROVIDED BY THIS WEBSITE IS FOR NEWS AND ENTERTAINMENT PURPOSES ONLY. ANY USE OF THIS INFORMATION IN VIOLATION OF FEDERAL, STATE, PROVINCIAL OR LOCAL LAWS IS STRICTLY PROHIBITED.
        </p>
        <div className="footer_links">
          <Link to="/support">SUPPORT</Link>
          <Link to="/privacy">PRIVACY POLICY</Link>
          <Link to="/terms">TERMS AND CONDITIONS</Link>
        </div>
        <small>2023 Vegas Dave © All Rights Reserved</small>
      </div>
    </div>
  )
}

export default Footer
