import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className='header_container'>
      <div className="sl_container"> 
        <Link className="logo_link" to="/"> 
          <img src="/images/logo.png" />
        </Link> 
        <div className="right_buttons">
          <Link to="/support">SUPPORT</Link>
          <Link to="/#package-details">PACKAGE DETAILS</Link>
        </div> 
      </div>
    </div>
  )
};

export default Header