import React from 'react'

const SuccessMessage = (props) => {
  return (
    <div className='success_message'>
      <i className="fa-solid fa-check"></i> 
      <p>Success: { props.message }</p> 
    </div>
  )
}

export default SuccessMessage;
